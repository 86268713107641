import React, { useContext } from 'react';
import styled from 'styled-components';
import { Card, Container } from './ui';
import { getThemeStatus } from '../utils';
import { SiteContext } from '../context/SiteContext';

const StyledThemeList = styled.section``;

const StyledInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const ThemeList = ({ items }) => {
  const { progress } = useContext(SiteContext);

  return (
    <StyledThemeList>
      <Container>
        <StyledInner>
          {items.map(
            (
              { contentful_id, introduction, image, slug, heading, questions },
              i
            ) => {
              const themeStatus =
                Object.keys(progress).length > 0
                  ? getThemeStatus(progress, {
                      contentful_id: contentful_id,
                      questions: questions,
                    })
                  : '';

              return (
                <Card
                  key={i}
                  description={introduction}
                  image={image}
                  heading={heading}
                  isTheme={true}
                  complete={themeStatus.complete}
                  totalQuestions={questions.length}
                  questionCompleteCount={themeStatus.questionCompleteCount}
                  slug={slug}
                />
              );
            }
          )}
        </StyledInner>
      </Container>
    </StyledThemeList>
  );
};
export default ThemeList;
