import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ThemeList from '../components/ThemeList';

const CurriculumPage = () => {
  const {
    contentfulSiteData: { siteName },
    contentfulCurriculum: { title, heading, metaDescription },
    allContentfulTheme: { nodes },
  } = useStaticQuery(graphql`
    query CurriculumPageQuery {
      contentfulSiteData {
        siteName
      }
      contentfulCurriculum {
        title
        metaDescription
        heading {
          raw
        }
      }
      allContentfulTheme(sort: { order: ASC, fields: order }) {
        nodes {
          contentful_id
          title
          slug
          image {
            gatsbyImageData(width: 484, height: 436)
            ...ImageFragment
          }
          introduction
          heading {
            raw
          }
          questions {
            contentful_id
            lessons {
              contentful_id
            }
          }
        }
      }
    }
  `);

  return (
    <Layout title={title} description={metaDescription} siteName={siteName}>
      <main>
        <Banner heading={heading || title} isExtended={true} />
        <ThemeList items={nodes} />
      </main>
    </Layout>
  );
};

export default CurriculumPage;
